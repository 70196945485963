import * as React from "react";

import './MatchCountdown.scss';
import { TickerMatch } from "../models";
import { TickerMatchSeries, TickerMatchState } from '../models/index';
import { MatchCardHeader } from '../view/match-cards/match-card/MatchCardHeader';
import { Countdown } from './Countdown';

interface Props {
  match: TickerMatch
  matchSeries: TickerMatchSeries,
  matchState: TickerMatchState
}

export const MatchCountdown = (props: Props) => {

  const {
    match,
    matchSeries,
    matchState
  } = props

  return (
    <div>
      <div>
        <MatchCardHeader match={match} matchSeries={matchSeries} matchState={matchState} />
        <Countdown match={match}></Countdown>
        <a className="homepage-link" href={"https://www.vbl-ticker.de/detail/" + match.id}>www.vbl-ticker.de</a>
      </div>
    </div>
  )
}
