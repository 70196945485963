import * as React from "react";

import './Countdown.scss';
import { TickerMatch } from "../models";
import { useEffect, useState } from 'react';
import { leadingZero } from '../utils';

interface Props {
  match: TickerMatch
}

export const Countdown = (props: Props) => {

  const { match } = props
  const [now, setNow] = useState(new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date().getTime())
    }, 1000);
    return () => clearInterval(interval);
  }, []);


  const countDownDate = new Date(match.date).getTime()
  // Find the distance between now and the count down date
  const distance = countDownDate - now;

  // Time calculations for days, hours, minutes and seconds
  const days = Math.floor(distance / (1000 * 60 * 60 * 24)) || 0
  const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)) || 0
  const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)) || 0
  const seconds = Math.floor((distance % (1000 * 60)) / 1000) || 0

  return (
    <div>
      <div className="countdown-wrapper">
        Nächstes Spiel in:
        <div className="countdown-display">
          <div>
            <div className="countdown-number">{leadingZero(days)}</div>
            <div>Tage</div>
          </div>
          <div>
            <div className="countdown-number">{leadingZero(hours)}</div>
            <div>Std.</div>
          </div>
          <div>
            <div className="countdown-number">{leadingZero(minutes)}</div>
            <div>Min.</div>
          </div>
          <div>
            <div className="countdown-number">{leadingZero(seconds)}</div>
            <div>Sek.</div>
          </div>
        </div>
      </div>
    </div>
  )
}
