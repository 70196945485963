import * as React from "react";

import './MatchInfoHeader.scss';
import { CurrentSetScore } from '../match-cards/match-card/CurrentSetScore';
import { TickerMatchState, TickerMatch } from '../../models/index';
import { TickerApiTeam } from '../../../../ticker-backend/src/models/api';
import { TeamScore } from '../../match/TeamScore';

interface Props {
  match: TickerMatch
  matchState: TickerMatchState
}

export const MatchInfoHeader = (props: Props) => {

  const getTeamLogo = (team: TickerApiTeam) => {
    const style = { backgroundImage: '' }
    style.backgroundImage = 'url(' + team.logoImage200 + ')'
    return <div className="TeamLogo-image">
      {team.logoImage200 ? <div className="LogoImage" style={style}></div> : <div className="AvatarLogo">{team.letter}</div>}
    </div>
  }

  const {
    matchState,
    match
  } = props

  const { team1, team2 } = match
  const { setPoints, finished } = matchState

  return (
    <div className="MatchInfoHeader">
      {getTeamLogo(team1)}
      <div className="set-score">
        <TeamScore score={setPoints.team1} finished={finished} />
        <CurrentSetScore matchState={matchState} />
        <TeamScore score={setPoints.team2} finished={finished} />
      </div>
      {getTeamLogo(team2)}
    </div>
  )
}
