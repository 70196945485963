import * as React from 'react';
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client';
import {
  persistReducer,
  persistStore,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import { PersistGate } from 'redux-persist/integration/react'
import { connectToTicker } from './socket';
import { BrowserRouter } from 'react-router-dom';
import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import 'normalize.css/normalize.css';

import { associationTickerStateReducer, teamTickerStateReducer } from './ticker-state/reducer';
import { dayFilterReducer } from './day-filter/reducer';
import { connectionReducer } from './connection/reducer';
import { favoritesReducer } from './match/favourite/reducer';
import { viewSelectionReducer } from './view/view-selection/reducer';
import { TickerMatch, TickerMatchSeries } from './models';
import { matchDetailViewReducer } from './view/match-detail/detail-view/reducer';
import './index.scss';
import App from './App';
import { filterNavReducer } from './filter-nav/reducer';
import TeamApp from './TeamApp';

export interface AppProps {
  tickerId: string
  teamId?: string
  headTitle: string
  appTitle: string
  footerText: string
  footerUrl: string
}

const ticker = document.getElementById('sams-ticker') as HTMLElement

const appProps: AppProps = {
  tickerId: ticker.getAttribute('tickerId') as string,
  teamId: ticker.getAttribute('teamId') as string,
  headTitle: ticker.getAttribute('headTitle') as string,
  appTitle: ticker.getAttribute('appTitle') as string,
  footerText: ticker.getAttribute('footerText') as string,
  footerUrl: ticker.getAttribute('footerUrl') as string
}

const persistConfig = {
  key: 'root',
  storage,
  blacklist: appProps.teamId
    ? ['tickerState', 'connection', 'router']
    : ['dayFilter', 'tickerState', 'connection', 'router', 'matchDetailView']
}

const teamReducer = combineReducers({
  connection: connectionReducer,
  tickerState: teamTickerStateReducer
})

export const associationReducer = combineReducers({
  connection: connectionReducer,
  favorites: favoritesReducer,
  tickerState: associationTickerStateReducer,
  dayFilter: dayFilterReducer,
  filterNav: filterNavReducer,
  matchDetailView: matchDetailViewReducer,
  selectedView: viewSelectionReducer
})

export const reducer = appProps.teamId
  ? teamReducer
  : associationReducer

export type AssociationRootState = ReturnType<typeof associationReducer>
export type TeamRootState = ReturnType<typeof teamReducer>

const persistedReducer = persistReducer(persistConfig, reducer as any)

const store = process.env.NODE_ENV === 'production'
  ? configureStore({
    reducer: persistedReducer, devTools: false,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      })
  })

  : configureStore({
    reducer: persistedReducer, devTools: true,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      })
  })
const persistor = persistStore(store)

export type TickerStore = typeof store

if (appProps.tickerId) {
  connectToTicker(appProps.tickerId, store, appProps.teamId)
}

const root = createRoot(ticker)

root.render(
  appProps.tickerId ?
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          {appProps.teamId ? <TeamApp appProps={appProps} /> : <App appProps={appProps}></App>}
        </BrowserRouter>
      </PersistGate>
    </Provider>
    :
    <h1>Bitte ergänzen Sie das html element mit der id <b>sams-ticker</b> mit den Attributen <b>tickerId</b> und <b>tickerType</b></h1>
)

export const filterMatchSeriesMatches = (matches: TickerMatch[], matchSeries: TickerMatchSeries): TickerMatch[] =>
  matches.filter(m => m.matchSeries === matchSeries.id)

export const filterFavoriteMatches = (state: AssociationRootState, matches: TickerMatch[]): TickerMatch[] =>
  matches.filter(m => state.favorites.indexOf(m.id) > -1)
