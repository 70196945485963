import * as React from 'react';

import { MatchCard } from './match-card/MatchCard';
import { getMatchStatus, sortMatches } from '../../utils';
import { TickerMode } from '../../../../ticker-backend/src/models/ticker';
import { TickerMatchStatesByUuid } from '../../ticker-state/reducer';
import { TickerMatchDay, TickerMatchSeriesByUuid } from '../../models';
import { FavoritesState } from '../../match/favourite/reducer';
import { useContext, useEffect } from 'react';
// @ts-ignore
import { SwipeableViewsContext } from 'react-swipeable-views';

interface Props {
  matchDay: TickerMatchDay
  matchStatesByUuid: TickerMatchStatesByUuid
  matchSeries: TickerMatchSeriesByUuid
  mode: TickerMode
  favorites?: FavoritesState
}

// The maximum of cards that fit in a row
const MAX_CARDS_IN_ROW = 4

export const MatchCards = (props: Props) => {

  const context: any = useContext(SwipeableViewsContext);
  useEffect(() => {
    context.slideUpdateHeight()
  })

  const {
    matchDay,
    matchStatesByUuid,
    matchSeries,
    mode,
    favorites } = props
  const { matches } = matchDay

  const shouldCenterCards = matches.length < MAX_CARDS_IN_ROW

  const cards = [...matches]
    .sort(sortMatches(matchStatesByUuid, matchSeries))
    .map(m => {
      const uuid = m.id
      const matchState = matchStatesByUuid[uuid]
      return <MatchCard key={uuid}
        match={m}
        matchUuid={uuid}
        matchState={matchState}
        status={getMatchStatus(matchState)}
        matchSeries={matchSeries[m.matchSeries]}
        favorites={favorites}
        mode={mode} />
    })

  return <div className={"MatchCards" + (shouldCenterCards ? " center" : "")}>
    {cards}
  </div>

}
