import * as React from "react";

import './SetScoreHistory.scss';
import { SetScoreHistoryEntry } from "./SetScoreHistoryEntry";
import { SetScore } from "../../../../shared/reducers/game-state/match-sets/set-score/set-score.reducer";

interface Props {
  setScores: SetScore[]
}

export const SetScoreHistory = React.memo((props: Props) => {
  const { setScores } = props
  return (
    <div className="SetScoreHistory">
      {setScores.map((s, i) => <SetScoreHistoryEntry score={s} key={i} setNumber={i + 1} />)}
    </div>
  )
})
