import * as React from 'react';
import './FilterNav.scss'
import { NavLink } from 'react-router-dom';
import { getMatchSeriesPath } from '../utils';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMars, faSearch, faTimes, faTransgender, faVenus } from '@fortawesome/free-solid-svg-icons';
import { AssociationRootState } from '..';
import { bindActionCreators, Dispatch } from 'redux';
import { setGenderFilter } from './reducer';
import { TickerMatchSeries } from '../models';
import { Gender } from '../../../ticker-backend/src/models/api';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const mapStateToProps = (state: AssociationRootState) => {
  return {
    matchSeries: state.tickerState.matchSeries,
    gender: state.filterNav.gender
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => bindActionCreators({
  setGenderFilter,
}, dispatch)

type Props = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatchToProps>


const FilterNav = ((props: Props) => {
  const { matchSeries } = props
  let location = useLocation()
  const activePathName = location.pathname
  const [matchSeriesList, setMatchSeriesList] = useState([] as TickerMatchSeries[])

  const [gender, setGenderFilter] = useState(undefined as Gender | undefined)

  useEffect(() => {
    setMatchSeriesList(Object.values(matchSeries).sort((a, b) => {
      const rank = a.orderLevel - b.orderLevel
      if (rank) return rank
      const x = a.name.toLowerCase()
      const y = b.name.toLowerCase()
      if (x < y) return -1
      if (x > y) return 1
      return 0
    }))
  },[matchSeries]);

  const hasMixed = Object.values(matchSeries).filter(m => m.gender === 'MIXED').length > 0
  const hasMale = Object.values(matchSeries).filter(m => m.gender === 'MALE').length > 0
  const hasFemale = Object.values(matchSeries).filter(m => m.gender === 'FEMALE').length > 0

  const selectFavorites = () => {
    setGenderFilter(undefined)
  }
  const showGenderFilter = [hasMixed, hasMale, hasFemale].filter(x => x).length > 1

  const onGenderChange = (newGender: Gender) => {
    gender === newGender ? setGenderFilter(undefined) : setGenderFilter(newGender)
  }

  const [searchInput, setSearchInput] = useState('')

  const onReset = () => {
    setGenderFilter(undefined)
    setSearchInput('')
    searchItems('')
  }

  const searchItems = (searchValue: string) => {
    setSearchInput(searchValue)
  }

  return <div className="FilterNav">
      <div className="group">
        <input type="text" required value={searchInput} onChange={e => searchItems(e.target.value)}/>
        <span className="highlight"></span>
        <span className="bar"></span>
        <label><FontAwesomeIcon icon={faSearch} /> Mannschaft | Spielklasse</label>
      </div>
      { showGenderFilter && <div className="gender-nav">
        { hasFemale &&
          <label className="container">
            <FontAwesomeIcon icon={faVenus} />
            <input type="checkbox"
              checked={gender === 'FEMALE'}
              onChange={() => onGenderChange('FEMALE')} />
            <span className="checkmark"></span>
          </label>
        }
        { hasMale &&
          <label className="container">
            <FontAwesomeIcon icon={faMars} />
            <input type="checkbox"
              checked={gender === 'MALE'}
              onChange={() => onGenderChange('MALE')} />
            <span className="checkmark"></span>
          </label>
        }
        { hasMixed &&
          <label className="container">
            <FontAwesomeIcon icon={faTransgender} />
            <input type="checkbox"
              checked={gender === 'MIXED'}
              onChange={() => onGenderChange('MIXED')} />
            <span className="checkmark"></span>
          </label>
        }
      </div>
    }

    {matchSeriesList.filter(m => {
      const res1 = m.name?.toLocaleLowerCase().search(searchInput?.toLocaleLowerCase()) > -1
      || m.shortName?.toLocaleLowerCase().search(searchInput?.toLocaleLowerCase()) > -1
      const res2 = m.teams.filter(t => {
        return t.name?.toLocaleLowerCase().search(searchInput?.toLocaleLowerCase()) > -1
        || t.shortName?.toLocaleLowerCase().search(searchInput?.toLocaleLowerCase()) > -1
        || t.clubCode?.toLocaleLowerCase().search(searchInput?.toLocaleLowerCase()) > -1
      }).length > 0
      const searchFilter = res1 || res2
      const genderFilter = gender ? m.gender === gender : true
      return searchFilter && genderFilter
    
    }).map(ms => {
      return activePathName !== getMatchSeriesPath(ms)
        ? <NavLink
          key={ms.id}
          to={getMatchSeriesPath(ms)}
          className={(navData) => navData.isActive ? "selected checkboxLink" : "checkboxLink" }>
          {ms.name}
        </NavLink>
        : <NavLink key="reset-matchseries" className={(navData) => !navData.isActive ? "selected checkboxLink" : "checkboxLink" } to={'/'}>
            {ms.name}
          </NavLink>
    })}
    <NavLink key="fav" className={_ => activePathName === '/favorites' ? "selected checkboxLink" : "checkboxLink" } onClick={selectFavorites} to={activePathName !== '/favorites' ? '/favorites' : ''}>Favoriten</NavLink>
    <NavLink key="reset" className="reset" onClick={() => onReset()} to={'/'}><FontAwesomeIcon icon={faTimes}/>Filter zurücksetzen</NavLink>
  </div>
})

export default connect(mapStateToProps, mapDispatchToProps)(FilterNav)
