import * as React from 'react';

import './LiveTicker.scss'
import { EventEntry } from './EventEntry';
import { TickerMatchState, TickerMatch } from '../../../models';

interface Props {
  match: TickerMatch
  matchState: TickerMatchState
}

export const LiveTicker = (props: Props) => {

    const { matchState } = props

    const eventHistory = matchState.eventHistory
    return <div className="LiveTicker">
      {eventHistory.map((e, i) => <div key={i}><EventEntry key={e.uuid} event={e} {...props} /></div>)}
    </div>

}
