import * as React from 'react';
import { connect } from 'react-redux';
import './TeamApp.scss';

import { Actions } from './actions';
import { AppProps, TeamRootState } from './index';
import { TickerMatchDay } from './models';
import { MatchCountdown } from './team/MatchCountdown';
import { MatchCardHeader } from './view/match-cards/match-card/MatchCardHeader';
import { MatchCardBody } from './view/match-cards/match-card/MatchCardBody';
import { getToday } from './utils';

const mapStateToProps = (state: TeamRootState) => {
  return {
    connection: state.connection,
    settings: state.tickerState.settings,
    error: state.tickerState.error,
    tickerState: state.tickerState
  }
}

type Props = ReturnType<typeof mapStateToProps> & { dispatch: React.Dispatch<Actions>, appProps: AppProps }

const App = (props: Props) => {

  const {
    error,
    tickerState
  } = props

  const matchDays = tickerState.matchDays

  if (tickerState.loading) {
    return <div>loading</div>
  }

  let matchDay: TickerMatchDay
  const today = getToday()
  const todaysMatchday = matchDays.find(m => new Date(m.date).toLocaleDateString() === today.toLocaleDateString())

  if (!todaysMatchday) {
    return <div>Keine Spiele vorhanden.</div>
  } else {
    if (todaysMatchday.matches.length > 0) {
      matchDay = todaysMatchday
    } else {
      const todayIdx = matchDays.indexOf(todaysMatchday)
      const latestMatchDay = matchDays.slice(0, todayIdx).reverse().find(m => m.matches.length > 0)
      if (latestMatchDay) {
        matchDay = latestMatchDay
      } else {
        const nextMatchDay = matchDays.slice(todayIdx + 1).find(m => m.matches.length > 0)
        if (nextMatchDay) {
          matchDay = nextMatchDay
        } else {
          return <div className="no-matches-container">
            <h1 className="team-ticker-heading">TEAM TICKER</h1>
            <div className="no-matches">Keine bevorstehenden Spiele</div>
          </div>
        }
      }
    }

  }

  const match = matchDay.matches[0]
  const uuid = match.id
  const matchState = tickerState.matchStatesByUuid[uuid]
  const matchSeries = tickerState.matchSeries[match.matchSeries]

  if (error) return <h1>{error}</h1>

  if (matchState?.started) {
    return (
      <div className="Team-ticker">
        <div className="Team-header">
          <div className="Team-title">LIVE TICKER</div>
        </div>
        <MatchCardHeader match={match} matchSeries={matchSeries} matchState={matchState} />
        <MatchCardBody match={match} matchState={matchState} />
      </div>
    );
  }

  return <div className="Team-ticker">
    <div className="Team-header">
      <div className="Team-title">COUNTDOWN</div>
    </div>
    <MatchCountdown key={uuid}
      match={match}
      matchState={matchState}
      matchSeries={matchSeries} />
  </div>

}

export default connect(mapStateToProps)(App)
