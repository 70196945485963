import * as React from 'react';
import { Route, Routes } from 'react-router-dom';

import './View.scss';
import MatchesOverview from './MatchesOverview';
import { AppProps } from '../index';
import { TickerSettings } from '../../../ticker-backend/src/models/ticker';
import {
  getMatchSeriesPath,
  getMatchStatus,
  getMatchDetailPath,
  flatMap,
  getMatchStatsPath
} from '../utils';
import MatchDetail from './match-detail/MatchDetail';
import MatchStats from './match-stats/MatchStats';
import { AssociationTickerState } from '../ticker-state/reducer';
import { TickerMatchSeries } from '../models';
import { MatchDetailView } from './match-detail/detail-view/reducer';

type Props = {
  appProps: AppProps
  settings: TickerSettings,
  tickerState: AssociationTickerState
  matchSeries?: TickerMatchSeries
  matchDetailView: MatchDetailView
}

class View extends React.Component<Props> {

  public render() {
    const {
      appProps,
      settings,
      tickerState,
      matchDetailView
    } = this.props

    const {
      matchDays,
      matchStatesByUuid,
      matchStatsByUuid,
      matchSeries
    } = tickerState

    return (
      <div className="View">
        <Routes>
          {settings.mode === 'DETAILED' &&
            flatMap((m => m.matches), matchDays).filter(m => matchStatesByUuid[m.id]).map(match => {
              const matchState = matchStatesByUuid[match.id]
              const ms = matchSeries[match.matchSeries]
              const matchStats = matchStatsByUuid[match.id]
              return <Route
                  key={getMatchDetailPath(match)}
                  path={getMatchDetailPath(match)}
                  element={<MatchDetail matchDetailView={matchDetailView} status={getMatchStatus(matchState)} match={match} matchSeries={ms} matchState={matchState} matchStats={matchStats} />} />
            })
          }

          {flatMap((m => m.matches), matchDays).filter(m => matchStatsByUuid[m.id]).map(match => {
              const matchStats = matchStatsByUuid[match.id]
              const matchState = matchStatesByUuid[match.id]
              const ms = matchSeries[match.matchSeries]
              return <Route
                  key={getMatchStatsPath(match)}
                  path={getMatchStatsPath(match)}
                  element={<MatchStats matchStats={matchStats} match={match} matchSeries={ms} matchState={matchState} />}/>
            })
          }

          {
            Object.keys(matchSeries).map(matchSeriesUuid => {
              const ms = matchSeries[matchSeriesUuid]
              return <Route
                  key={matchSeriesUuid}
                  path={getMatchSeriesPath(ms)}
                  element={<MatchesOverview matchSeriesUuid={matchSeriesUuid} appProps={appProps} />}/>
            })
          }
          <Route path={"/favorites"} element={<MatchesOverview favoritesRoute={true} appProps={appProps}/>}/>
          <Route path={"/*"} element={ <MatchesOverview appProps={appProps}/> }/>
        </Routes>

      </div>
    )
  }

}

export default View
