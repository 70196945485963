import * as React from 'react';

import './LiveLineups.scss'
import { TickerMatchState, TickerMatch } from '../../../models';
import { LiveLineup } from './LiveLineup';

interface Props {
  matchState: TickerMatchState
  match: TickerMatch
}

export const LiveLineups = (props: Props) => {

  const { matchState, match } = props
  const { teamSquads, teamLineups, serving } = matchState
  return <div className="LiveLineups">
    <div className="tile tile-shadow">
      <LiveLineup teamSquad={teamSquads.team1} lineup={teamLineups.team1} team={match.team1} isServing={serving === 'team1'} side="left" />
      <LiveLineup teamSquad={teamSquads.team2} lineup={teamLineups.team2} team={match.team2} isServing={serving === 'team2'} side="right" />
    </div>
  </div>

}
