import * as React from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars, faSlidersH } from '@fortawesome/free-solid-svg-icons'

import './App.scss';
import { Actions } from './actions';
import Footer from './footer';
import SideNav from './side-nav/SideNav';
import MainNav from './main-nav/MainNav';
import FilterNav from './filter-nav/FilterNav';
import View from './view/View';
import { AppProps, AssociationRootState } from '.';
import ScrollToTop from './ScrollToTop';


const mapStateToProps = (state: AssociationRootState) => {
  return {
    connection: state.connection,
    settings: state.tickerState.settings,
    error: state.tickerState.error,
    tickerState: state.tickerState,
    matchDetailView: state.matchDetailView.view
  }
}

type Props = ReturnType<typeof mapStateToProps> & { dispatch: React.Dispatch<Actions>, appProps: AppProps }

const App = (props: Props) => {

  const [leftSideBarVisible, toggleLeftSideBar] = React.useState(false)
  const [rightSideBarVisible, toggleRightSideBar] = React.useState(false)

  const isOverlayVisible = () => {
    return leftSideBarVisible || rightSideBarVisible
  }

  const hideSideBars = () => {
    toggleLeftSideBar(false)
    toggleRightSideBar(false)
  }

  const onToggleSideBarLeft = (collapsed: boolean) => {
    toggleLeftSideBar(!collapsed)
  }

  const onToggleSideBarRight = (collapsed: boolean) => {
    toggleRightSideBar(!collapsed)
  }

  const {
    error,
    appProps,
    tickerState,
    settings,
    matchDetailView
  } = props

  if (error) return <h1>{error}</h1>
  let location = useLocation();

  const id = location.pathname.replace('/', '')
  const ms = tickerState.matchSeries[id]

  return (
    <ScrollToTop>
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{ms ? ms.name : appProps.headTitle}</title>
        </Helmet>

        <SideNav nav={<MainNav />} title="MENÜ" position="left"
          toggleIcon={<FontAwesomeIcon icon={faBars} />}
          onToggle={onToggleSideBarLeft}
          visible={leftSideBarVisible}
          hidden={rightSideBarVisible} />

        <SideNav nav={<FilterNav />} title="FILTER" position="right"
          toggleIcon={<FontAwesomeIcon icon={faSlidersH} rotation={90} />}
          onToggle={onToggleSideBarRight}
          visible={rightSideBarVisible}
          hidden={leftSideBarVisible} />

        <div className={'App-overlay' + (isOverlayVisible() ? ' active' : '')} onClick={hideSideBars} />

        <View matchDetailView={matchDetailView} matchSeries={ms} tickerState={tickerState} settings={settings} appProps={appProps} />
        <Footer copyright={appProps.footerText} url={appProps.footerUrl} />
      </div>
    </ScrollToTop>
  );

}

export default connect(mapStateToProps)(App)
