import * as React from 'react';

import './PlayerCard.scss'
import { Avatar } from '../../../shared/Avatar';
import { getInitials } from '../../../utils';
import { EventPlayer } from '../../../../../shared/event-api/model';

interface Props {
  player: EventPlayer;
  libero: boolean;
  captain: boolean;
  defaultLibero: boolean;
  isServing: boolean;
  index: number;
}

export const PlayerCard = (props: Props) => {

  const getPlayerPositionLabel = (): string => {
    const { player, libero, defaultLibero, captain } = props;
    let parts = [];
    if (captain) {
      parts.push('C');
    }
    if (libero) {
      defaultLibero ? parts.push('L1') : parts.push('L');
    }
    // Todo: find better place for this:
    switch (player.position) {
      case 'WING_SPIKER':
        parts.push('AA'); break;
      case 'OPPOSITE':
        parts.push('D'); break;
      case 'SETTER':
        parts.push('Z'); break;
      case 'MIDDLE_BLOCKER':
        parts.push('MB'); break;
      case 'LIBERO':
        if (!libero) {
          parts.push('L');
        }
        break;
      case 'UNIVERSAL':
        parts.push('U'); break;
    }
    return parts.join(' | ');
  }

  const { player, isServing, index } = props
  if (player == null) {
    return <div className="PlayerCard" />
  }
  const initials = getInitials(player)

  return <div className="PlayerCard">
    <Avatar placeholder={initials} image={player.portraitPhoto} />
    <div className="PlayerCard-text">
      <div className="PlayerCard-jersey">{player.jerseyNumber}</div>
      {player != null ? player.lastName : '-'}
    </div>
    {<div className="PlayerCard-footer PlayerCard-position">{getPlayerPositionLabel()}</div>}
    {(isServing && index === 0) && <img width="40vw" src="/images/ball-icon.png" />}
  </div>

}
