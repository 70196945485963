import * as React from "react";
import './TeamLogo.scss';
import { TickerTeam } from "../models";
import { TeamSide } from "../../../shared/interfaces/models";

interface Props {
  team: TickerTeam
  side: TeamSide
  rescheduled?: boolean
}

export const TeamLogo = React.memo((props: Props) => {
  const { team, side, rescheduled } = props
  const { logoImage200, name, letter } = team
  const style = { backgroundImage: '' }
  if (logoImage200) {
    style.backgroundImage = 'url(' + logoImage200 + ')'
  }
  return (
    <div className={"TeamLogo " + side}>
      <div className="TeamLogo-image">
        {logoImage200 ? <div className={"LogoImage" + (rescheduled ? ' rescheduled': '')} style={style}></div> : <div className="AvatarLogo">{letter}</div>}
      </div>
      <div className={"TeamLogo-name" + (rescheduled ? ' rescheduled': '')}>{name}</div>
    </div>
  )
})
