import * as React from "react";

import './SetScoreHistoryEntry.scss';
import { SetScore } from "../../../../shared/reducers/game-state/match-sets/set-score/set-score.reducer";

interface Props {
  score: SetScore
  setNumber: number
}

export const SetScoreHistoryEntry = React.memo((props: Props) => {
  const { score, setNumber } = props
  return (
    <div className="SetScoreHistoryEntry">
      <div className="team-score">{score.team1}</div>
      <div className="set-number">({setNumber})</div>
      <div className="team-score">{score.team2}</div>
    </div>
  )
})
