import * as React from "react";

import './Scores.scss';

import { TeamScore } from './TeamScore';
import { SetScoreHistory } from './score-history/SetScoreHistory';
import { SetPoints } from "../../../shared/reducers/game-state/match-sets/match-sets.reducer";
import { TickerMatchSet } from "../models";

type Props = {
  matchSets: TickerMatchSet[]
  setPoints: SetPoints
  finished: boolean
}

export const Scores = React.memo((props: Props) => {
  const { matchSets, setPoints, finished } = props
  return (
    <div className="Scores">
      <TeamScore score={setPoints.team1} finished={finished} />
      <SetScoreHistory setScores={matchSets.map(s => s.setScore)} />
      <TeamScore score={setPoints.team2} finished={finished} />
    </div>
  )
})
