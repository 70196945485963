import * as React from 'react';

import './RankingCard.scss'
import { Avatar } from '../../../shared/Avatar';
import { TickerRanking } from '../../../models';
import { TickerApiRanking } from '../../../../../ticker-backend/src/models/api';

interface Props {
  ranking: TickerRanking
}

export const RankingCard = (props: Props) => {

  const getRankingPosition = (ranking: TickerApiRanking) => {
    return ranking.scoreDetails?.ranking || ranking.rankingPosition
  }

  const ranking = props.ranking
  return <div className="RankingCard">
    <div className="Ranking-position">
      {getRankingPosition(ranking)}
    </div>
    <div>
      <Avatar placeholder={ranking.team.letter} image={ranking.team.logoImage200} />
    </div>
    <div>
      {ranking.team.clubCode}
    </div>
    <div>
      {ranking.scoreDetails.matchesPlayed}
    </div>
    <div className="Ranking-score">
      {ranking.scoreDetails.winScore}
    </div>
  </div>

}
