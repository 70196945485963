import * as React from "react";
import { SetScore } from "../../../../../../shared/reducers/game-state/match-sets/set-score/set-score.reducer";

interface Props {
  setScore: SetScore
}

export const SetScoreDisplay = (props: Props) => {
  const { setScore } = props
  return <div>Aktueller Spielstand: {setScore.team1}:{setScore.team2}</div>
}

